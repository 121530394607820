import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(){ }

    login(dados: any): void {
        localStorage.setItem('token', dados.token);
    }

    logoff(): void {
        localStorage.removeItem('token');
    }

    getToken(): string {
        return localStorage.getItem('token') + "";
    }

    isLogged(): boolean {
        return localStorage.getItem('token') != null;
    }

    isAdmin(): boolean {
        return localStorage.getItem('token') == '1';
    }
}
